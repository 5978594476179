<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image textmining-bg"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title" style="color:#fff;">Text Mining</h2>
              <p>
                
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <div class="rn-service-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col lg="12" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title">
                        <h2 class="heading-title">텍스트 마이닝:</h2>
                        <h2 class="heading-title">비정형 데이터에서 가치 창출의 시작</h2>
                        <p class="description pt--80" align="justify">
                          텍스트 마이닝은 비정형 텍스트 데이터의 바다에서 유용한 정보의 보석을 찾아내는 과정으로, 컴퓨터 과학의 깊은 이론, 데이터 과학의 혁신적 방법론, 통계학의 정밀한 분석 기법, 그리고 자연어 처리(NLP)의 섬세한 언어 이해 능력이 어우러진 분야입니다. 이 과정은 불완전하고 복잡한 데이터의 미로 속에서 명확한 길을 찾아내며, 이를 위해 데이터의 꼼꼼한 전처리, 의미 있는 특성의 추출, 숨겨진 패턴의 인식, 감정의 미묘한 뉘앙스를 포착하는 감성 분석, 그리고 다층적 주제 모델링과 실질적인 정보의 추출이라는 중요한 단계들을 거칩니다.
                        </p>
                        <p class="description" align="justify">
                          이러한 텍스트 마이닝의 기술은 소셜 미디어의 풍부한 의견 속에서 트렌드를 읽어내는 분석, 시장 조사에서 소비자의 목소리를 해석하는 통찰, 의료 분야에서 환자의 기록을 통해 진단을 돕는 지원, 그리고 금융 시장의 뉴스와 보고서를 분석하여 투자의 방향을 제시하는 등 다양한 영역에서 중요한 역할을 수행하며, 끊임없이 증가하는 데이터의 양을 효과적으로 이해하고 분석하는 데 있어 필수적인 도구가 되고 있습니다.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="12" md="12" cols="12 mt--30 mb--120">
                    <div class="thumb position-relative">
                      <div class="thumbnail position-relative mb--60">
                        <img class="w-100" src="../../assets/images/technology/textmining01.png" alt="" />
                      </div>
                      <!-- <div class="thumbnail position-relative">
                        <img class="w-100" src="../../assets/images/technology/searchengine01.png" alt="" />
                      </div> -->
                    </div>
                  </v-col>
                  
                  <v-col lg="12" md="12" cols="12">
                  </v-col>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Start Counterup Area  -->
    <div class="rn-counterup-area ptb--120 bg_color--5">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="text-center section-title mb--30">
            <span class="subtitle">문장 긍부정 파악하기</span>
            <h4>
              ▶ 극성: -2, 감정 분류: [두려움, 긴장감, 서두름]. 감정 점수[1, 1, 1]
            </h4>
          </div>
        </v-col>
        <ServiceTwo />
      </v-row>
    </v-container>
  </div>
  <!-- End Counterup Area  -->

  <!-- Start Counterup Area  -->
  <div class="rn-counterup-area ptb--120 bg_color--1">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="text-center section-title mb--30">
            <h4 class="heading-title">데이터 시각화</h4>
                <p class="description"> 차트는 데이터 특성에 따라 기호, 텍스트, 도형 등으로 표현하여 한눈에 볼 수 없는 많은 양의 정보를 직관적으로 볼수 있도록 도와줍니다.
                </p>
          </div>
        </v-col>
        <ServiceTwoV2 />
      </v-row>
    </v-container>
  </div>
  <!-- End Counterup Area  -->

    <div class="rn-service-details ptb--120 bg_color--5" style="display:none;">
      <v-container>
        <!-- Start Single Content  -->
        <v-row class="sercice-details-content align-center row--35">
          <v-col lg="6" md="6" cols="12" order="2" order-md="1">
            <div class="details mt_md--30 mt_sm--30">
              <div class="section-title">
                <h2 class="heading-title">메인추천</h2>
                <p class="description">
                  사용자의 관심데이터와 트렌드를 바탕으로 메인화면을 통해 다양한 기업을 추천해 줍니다.
                </p>
              </div>
            </div>
          </v-col>
          <v-col lg="6" md="2" cols="12" order="1" order-md="2">
            <div class="thumb position-relative">
              <img
                class="w-100" src="../../assets/images/service/service-02.png"
                alt="Service Images"
              />
            </div>
          </v-col>
          
        </v-row>
        <!-- End Single Content  -->
      </v-container>
    </div>
    
    <Footer />
  </div>
</template>

<script>
  import ServiceTwo from "../../components/tech/ServiceTwo";
  import ServiceTwoV2 from "../../components/tech/ServiceTwoV2";
  import feather from "feather-icons";
  import Header from "../../components/header/Header";
  import Footer from "../../components/footer/Footer";
  import PricingPlan from "../../components/pricing-plan/PricingPlan";
  export default {
    components: {
      ServiceTwo,
      ServiceTwoV2,
      Header,
      Footer,
      PricingPlan,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
        items: [
          {
            thumb: require("../../assets/images/product/dstocks_site.png"),
            src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
          },
        ],
        index: null,
        listOne: [
          {
            id: 1,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 2,
            icon: "check",
            desc: `Digital Marketing going to.`,
          },
          {
            id: 3,
            icon: "check",
            desc: ` Marketing Agency passage of.`,
          },
          {
            id: 4,
            icon: "check",
            desc: ` Seo Friendly you are going.`,
          },
          {
            id: 5,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 6,
            icon: "check",
            desc: `Fast-Track Your business`,
          },
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
