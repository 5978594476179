<template>
    <!-- Start Service Area  -->
    <v-row class="service-main-wrapper">
      <!-- Start Single Service  -->
      <v-col
        lg="6"
        md="6"
        sm="6"
        cols="12"
        v-for="(service, i) in serviceContent"
        :key="i"
      >
        <div class="text-left service service__style--2">
            <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.icon)"></div>
            </div>
              <div class="content">
                <h4 class="heading-title">{{ service.title }}</h4>
                <p align="justify">
                  {{ service.desc }}
                </p>
              </div>
            </div>
        </div>
      </v-col>
      <!-- End Single Service  -->
    </v-row>
    <!-- Start Service Area  -->
  </template>
  
  <script>
    import feather from "feather-icons";
    export default {
      data() {
        return {
          serviceContent: [
            {
              icon: "layers",
              title: "원문 내용",
              desc: `앨리스는 조심스러운 목소리로 “저, 왜 장미를 색칠하고 있는지 말해줄래요?”하고 물었다. 다섯과 일곱은 아무 말 없이 둘을 쳐다 보았고, 둘은 작은 목소리로 이야기를 시작했다. “왜냐면, 사실은, 보다시피, 아가씨. 원래 여기는 빨간 장미 정원인데 우리가 그만 실수로 흰 장미 한 그루를 심었답니다. 만약 여왕께서 이걸 알게 되면, 알다시피, 우리 모두 목이 달아나요...(생략)”`,
            },
            {
              icon: "monitor",
              title: "분석 결과",
              desc: `이 텍스트에는 두려움, 긴장감, 그리고 서두름의 감정이 내포되어 있습니다. 정원사들이 실수로 흰 장미를 심은 후, 여왕이 이를 발견하면 큰 처벌을 받을 것이라는 두려움 때문에 장미를 빨간색으로 칠하고 있습니다. 이러한 상황은 부정적인 감정을 유발하며, 특히 두려움과 긴장감을 중심으로 합니다. 여왕의 등장은 이러한 긴장감을 더욱 고조시키는 요소로 작용합니다.`,
            },
          ],
        };
      },
      methods: {
        iconSvg(icon) {
          return feather.icons[icon].toSvg();
        },
      },
    };
  </script>
  