<template>
    <!-- Start Service Area  -->
    <v-row class="service-main-wrapper">
      <!-- Start Single Service  -->
      <v-col
        lg="6"
        md="6"
        sm="6"
        cols="12"
        v-for="(service, i) in serviceContent"
        :key="i"
      >
        <div class="text-left service service__style--2">
            <div class="service">
              <div class="thumbnai">
                <img :src="service.src" alt="Corporate Images" />
              </div>
            </div>
        </div>
      </v-col>
      <!-- End Single Service  -->
    </v-row>
    <!-- Start Service Area  -->
  </template>
  
  <script>
    import feather from "feather-icons";
    export default {
      data() {
        return {
          serviceContent: [
            {
              src: require("../../assets/images/technology/textmining_barchart.png"),
            },
            {
              src: require("../../assets/images/technology/textmining_wordcloud.png"),

            },
          ],
        };
      },
      methods: {
        iconSvg(icon) {
          return feather.icons[icon].toSvg();
        },
      },
    };
  </script>
  